@import "app/colours.css";
@import "app/breakpoints.scss";

.TasksOverlay {
    width: 100vw;
    height: 100vh;
    height: 100svh;
    display: flex;
    flex-direction: column;
    background-color: var(--neutral);
    position: relative;

    @include tablet-portrait {
        width: 80vw;
        max-height: 95vh;
        max-width: 930px;
        box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
        border-radius: 24px;
        height: unset;
    }

    @include tablet-landscape {
        width: 80vw;
        max-height: 90vh;
    }
}

.heading {
    font-weight: bold;
    text-align: left;
    font-size: 1.5rem;
    padding: 1.5rem;
    border-bottom: 1px solid var(--neutral400);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--neutral);
    z-index: 2;
}

.heading svg {
    width: 2rem
}

.contentContainer {
    flex-grow: 1;
    overflow: auto;

    @include tablet-portrait {
        min-height: 420px;
    }
}

.content {
    padding: 1.5rem;
}

.TasksOverlay footer {
    padding: 1.5rem;
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    background-color: var(--neutral);
    border-top: 1px solid var(--neutral400);
}

.completeAll {
    font-size: 1.125rem;
    color: var(--main-tint);
    font-weight: bold;
    padding: 1rem 0;
    cursor: pointer;
}

.save {
    background-color: unset;
    color: var(--main-tint);
    border: 2px solid var(--main-tint);
}

.save:focus, .save:hover {
    background-color: var(--main-tint);
    color: white;
}

.disabled {
    background-color: var(--neutral400);
    opacity: 0.6;
    cursor: not-allowed;
}

.warning {
    position: absolute;
    width: 100%;
    height: 100%;
}