@import "app/colours.css";
@import "app/breakpoints.scss";

:root {
    --overdue-background: #F47452;
    --overdue-status-background: rgb(255, 60, 0);
    --due-status-background: var(--success-pressed);
}

.popoverArrowContainer {}

.popoverArrow {}

.popoverGroup {
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
    max-width: 90vw;
    overflow: auto;
    max-height: 80svh;

    @include tablet-portrait {
        max-width: unset;
        max-height: 90svh;
    }
}

.popoverModal {
    margin: 1rem auto;
}

.closeContainer {
    padding: 0.5rem 0.2rem 0.5rem 2rem;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    align-items: baseline;

    @include tablet-portrait {
        display: none;
    }
}

.closeContainer h3 {
    font-weight: bold;
    font-size: 1.25rem;
}

.close {
    padding: 0.5rem;
}

.popoverGroup ul {
    width: 90vw;
    list-style: none;
    padding: 0;
    margin: 0;
    overflow: auto;

    @include tablet-portrait {
        width: unset;
    }
}

.popoverGroup li {
    padding: 1rem 2rem;
    border-bottom: 1px solid #D8DAE5;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.popoverGroup li.overdue,
.popoverGroup li.due {
    padding-right: 1rem;
}

.popoverGroup li.templateName {
    font-size: 0.85rem;
    color: rgb(75, 75, 75);
    padding-left: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    align-items: left;
    cursor: default;
    background-color: rgb(244, 244, 244);
}

.popoverGroup li.templateName:hover {
    background-color: rgb(244, 244, 244);
}

.popoverGroup li:hover {
    background-color: rgb(244, 244, 244);
}

.popoverGroup li:last-of-type {
    border-bottom: 0;
}

.popoverGroup li > :first-child {
    margin-right: 0.5rem;
}

.popoverGroup li > svg {
    position: absolute;
    left: 1.5rem;
}

.inProgress {
    background-color: var(--warning-light-bg);
}

.titleContent {
    font-size: 0.9rem;
    font-weight: bold;
    border-radius: 4px;
    padding: 4px 6px;
    color: white;
    display: flex;
    flex-shrink: 0;
}

.titleContent.overdue  {
    background-color: var(--overdue-status-background);
}

.titleContent.due {
    background-color: var(--due-status-background);
}

.lastPerformed {
    background-color: var(--success-pressed);
}

.lastPerformedWarn {
    background-color: var(--error);
}

.lastPerformedHide {
    display: none;
}

.icon {
    display: flex;
    align-items: center;
    margin-right: 0.4em;
    fill: #fff;
}